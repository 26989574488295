import styled from "styled-components"

import MuiSkeleton from "@material-ui/lab/Skeleton"

import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export function HomeLoadingState() {
  return (
    <HomeLoadingStateBox>
      <Skeleton />
      <Skeleton />
    </HomeLoadingStateBox>
  )
}

const Skeleton = styled(MuiSkeleton)`
  background: ${backgroundGrayV2};
`

const HomeLoadingStateBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`
